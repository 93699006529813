import React, { ReactElement, useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { NotificationId } from '@mindfulchefuk/features/Notifications/interfaces'
import { removeNotification } from '@mindfulchefuk/features/Notifications/notificationsSlice'
import { Notification, TNotificationProps } from '@mindfulchefuk/design-system'
import useScrollToTop from '@mindfulchefuk/hooks/useScrollToTop'

interface IBannerNotification
  extends Omit<TNotificationProps, 'type' | 'title'> {
  isDismissable?: boolean
  shouldAutoDismiss?: boolean | number
  title: string
  id: NotificationId
}

export const BannerNotification = ({
  id,
  isDismissable,
  variant,
  shouldAutoDismiss,
  ...notificationProps
}: IBannerNotification): ReactElement => {
  const dispatch = useDispatch()

  const handleDismiss = useCallback(() => {
    dispatch(removeNotification(id))
  }, [dispatch, id])

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>
    const autoDismissTime =
      typeof shouldAutoDismiss === 'number' && shouldAutoDismiss > 0
        ? shouldAutoDismiss * 1000
        : 15 * 1000

    if (shouldAutoDismiss) {
      timeout = setTimeout(handleDismiss, autoDismissTime)
    }

    return () => clearTimeout(timeout)
  }, [handleDismiss, shouldAutoDismiss])

  useScrollToTop([])

  return (
    <Notification
      id={id}
      onDismiss={isDismissable ? handleDismiss : undefined}
      type="banner"
      variant={variant}
      {...notificationProps}
    />
  )
}
