import React, { ReactElement } from 'react'
import { Checkbox, Dropdown, Stack, Text } from '@mindfulchefuk/design-system'
import { TFoodGroup } from '@mindfulchefuk/config/mealPlanConfig'
import {
  foodGroupsConfig,
  getIncludedFoodGroups,
} from '@mindfulchefuk/config/foodGroupsConfig'
import { RecipeFilter } from './RecipeFilter'
import { RecipeFilterHandlers } from './RecipeFilter.types'

type TRecipeFilterFoodGroups = {
  onChange: RecipeFilterHandlers['foodGroups']
  onToggle: () => void
  isOpen: boolean
  excludedFoodGroups: TFoodGroup[]
  disabledFoodGroups: TFoodGroup[]
}

export const getFoodGroupsLabel = (excludedFoodGroups: TFoodGroup[]) => {
  const includedFoodGroups = getIncludedFoodGroups(excludedFoodGroups)

  if (excludedFoodGroups.length === 0) return 'All healthy recipes'

  if (includedFoodGroups.length === 1 && includedFoodGroups.includes('Vegan')) {
    return 'Vegan'
  }

  if (
    includedFoodGroups.includes('Fish') ||
    includedFoodGroups.includes('Shellfish')
  ) {
    const isPescetarian = includedFoodGroups.every((foodGroup) => {
      return (
        foodGroup !== 'Beef' &&
        foodGroup !== 'Lamb' &&
        foodGroup !== 'Pork' &&
        foodGroup !== 'Poultry'
      )
    })
    if (isPescetarian) return 'Pescetarian'
  }

  //if meat and fish we want to return the label 'selected proteins' and return all meats and fish proteins and no vegan and dairy proteins
  //if meat only, we want to return the label 'selected proteins' */

  if (includedFoodGroups.length > 1) {
    const isMeatAndFish = includedFoodGroups.every((foodGroup) => {
      return foodGroup !== 'Vegan' && foodGroup !== 'Dairy'
    })
    if (isMeatAndFish) return 'Meat & Fish'
  }

  return 'Selected proteins'
}

export const RecipeFilterFoodGroups = ({
  onChange,
  onToggle,
  isOpen,
  excludedFoodGroups,
  disabledFoodGroups,
}: TRecipeFilterFoodGroups): ReactElement => {
  const defaultMinimumFoodGroups = foodGroupsConfig
    .filter(({ value }) => value !== 'Vegan')
    .map(({ value }) => value)

  const foodGroupsUnChecked = Array.from(
    new Set([...excludedFoodGroups, ...disabledFoodGroups])
  )

  const handleChange = (foodGroup: TFoodGroup) => {
    // Default to Vegan if all groups are deselected
    if (
      foodGroupsUnChecked.length + 1 === foodGroupsConfig.length &&
      !foodGroupsUnChecked.includes(foodGroup)
    ) {
      onChange(defaultMinimumFoodGroups)
      return
    }

    if (excludedFoodGroups.includes(foodGroup)) {
      onChange(excludedFoodGroups.filter((fg) => fg !== foodGroup))
    } else {
      onChange([...excludedFoodGroups, foodGroup])
    }
  }

  return (
    <RecipeFilter
      id="food-groups"
      icon="knife-and-fork"
      label={getFoodGroupsLabel(excludedFoodGroups)}
      isOpen={isOpen}
      onToggle={onToggle}
    >
      <Dropdown
        isOpen={isOpen}
        onToggle={onToggle}
        alignment="left"
        width={240}
      >
        <Text variant="body-sm-500-caps" mb={12} color="aubergine">
          Choose your proteins
        </Text>
        <Stack spacing={10}>
          {foodGroupsConfig.map(({ value, filterLabel }) => {
            const id = `food-groups-${value}`

            const disabled = disabledFoodGroups.includes(value)

            const displayLabel = filterLabel || value

            return (
              <Checkbox
                id={id}
                data-testid={`${id}-checkbox`}
                key={id}
                value={value}
                disabled={disabled}
                onChange={() => handleChange(value)}
                checked={!foodGroupsUnChecked.includes(value)}
              >
                {displayLabel}
              </Checkbox>
            )
          })}
        </Stack>
      </Dropdown>
    </RecipeFilter>
  )
}
