import React, { ReactElement, ReactNode } from 'react'
import NextLink from 'next/link'
import { Anchor } from '@mindfulchefuk/design-system'

type TFooterLink = {
  children: ReactNode
  href: string
  openInNewWindow: boolean
  reference: string
}

export const FooterLink = ({
  children,
  href,
  openInNewWindow,
  reference,
}: TFooterLink): ReactElement => {
  const anchor = (
    <Anchor
      data-testid={`footer-link-${reference}`}
      href={href}
      variant={{ base: 'body-xl', md: 'body-sm', lg: 'body-lg' }}
      color="inherit"
      target={openInNewWindow ? '_blank' : '_self'}
    >
      {children}
    </Anchor>
  )

  return openInNewWindow ? anchor : <NextLink href={href}>{anchor}</NextLink>
}
