import React, { ReactElement } from 'react'
import NextLink from 'next/link'

import { Text } from '@mindfulchefuk/design-system/Text'
import styled from '@emotion/styled'
import { theme } from '@mindfulchefuk/design-system/system'
import { useNavClickHandler } from '@mindfulchefuk/features/Navigation/hooks'
import { Link } from '@mindfulchefuk/design-system/Link'
import { NavLinkData } from '@mindfulchefuk/features/Navigation/interfaces'

const NavLinkText = styled(Text)`
  &:hover {
    text-decoration: underline;
    text-underline-offset: 2px;
    font-weight: ${theme.typography['body-md-500']};
  }
`

const StyledLink = styled(Link)`
  text-decoration: none;
  &:hover {
    text-decoration: underline;
    text-underline-offset: 2px;
    font-weight: ${theme.typography['body-md-500']};
  }
`

type TNavLink = NavLinkData & {
  onClick?: (linkId: string) => void
  external?: boolean
}

export const NavLink = ({
  href,
  label,
  onClick,
  id,
  external = false,
}: TNavLink): ReactElement => {
  const handleClick = useNavClickHandler({
    action: 'nav-link-clicked',
    label: id,
    onClick,
  })

  return external ? (
    <StyledLink
      href={href}
      variant="body-md"
      onClick={handleClick}
      py={{ base: 8, md: 6 }}
      color="aubergine"
      display="block"
      id={id}
    >
      {label}
    </StyledLink>
  ) : (
    <NextLink href={href} passHref>
      <NavLinkText
        as="a"
        variant="body-md"
        onClick={handleClick}
        py={{ base: 8, md: 6 }}
        color="aubergine"
        display="block"
        id={id}
      >
        {label}
      </NavLinkText>
    </NextLink>
  )
}
