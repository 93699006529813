import React, { ReactElement } from 'react'
import { WarningModal } from '@mindfulchefuk/components/MyAccount/WarningModal'
import { PandaButton, Text } from '@mindfulchefuk/design-system'

const UpdatePreferencesText = (): ReactElement => (
  <>
    <Text as="p" mb={16}>
      Updating your preferences will change the recipes{' '}
      <Text as="span" variant="body-md-500">
        for all your subscription deliveries with recipes in them.
      </Text>
    </Text>
    <Text as="p" mb={16}>
      Ready to Go items will also be removed{' '}
      <Text as="span" variant="body-md-500">
        from subscription deliveries for which the delivery date is changed,
      </Text>{' '}
      and any reward offers applied to your orders may be reset.
    </Text>
    <Text as="p" mb={36} fontStyle="italic">
      After updating, please check your orders to ensure you are happy with the
      changes made.
    </Text>
  </>
)

const UpdatePreferencesTextWhilstPaused = (): ReactElement => (
  <>
    <Text as="p" mb={16}>
      Updating your preferences will change the recipes{' '}
      <Text as="span" variant="body-md-500">
        for all your subscription deliveries outside of your pause period.
      </Text>
      Any reward offers applied to your orders may be reset.
    </Text>
    <Text as="p" mb={36}>
      After updating, please check your orders to ensure you are happy with the
      changes made.
    </Text>
  </>
)

export const UpdateSubscribedPreferencesModal = ({
  savePreferences,
  onModalClose,
  userIsPaused,
}: {
  savePreferences: (hasActiveSubscription: boolean) => void
  onModalClose: () => void
  userIsPaused: boolean
}): ReactElement => {
  const Copy = userIsPaused
    ? UpdatePreferencesTextWhilstPaused
    : UpdatePreferencesText

  return (
    <WarningModal
      title="Making changes?"
      ctas={
        <>
          <PandaButton
            variant="primary"
            size="medium"
            onClick={() => savePreferences(true)}
          >
            Confirm changes
          </PandaButton>
          <PandaButton variant="secondary" size="medium" onClick={onModalClose}>
            Cancel
          </PandaButton>
        </>
      }
    >
      <Copy />
    </WarningModal>
  )
}
