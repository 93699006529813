/* eslint-disable camelcase */
import { differenceInCalendarDays, isAfter, isBefore, isEqual } from 'date-fns'
import {
  APIDelivery,
  Delivery,
} from '@mindfulchefuk/features/Delivery/interfaces'
import { DELIVERY_EDIT_GRACE_PERIOD } from '@mindfulchefuk/constants'
import getTotal from '@mindfulchefuk/utils/getTotal'
import { ProductContent } from '@mindfulchefuk/features/Products/interfaces'
import { dateFormats } from '@mindfulchefuk/lib/dateFormats'

export const transformDelivery = (apiDelivery: APIDelivery): Delivery => {
  const {
    contents,
    contents_assigner,
    delivery_date,
    delivery_option_id,
    id,
    one_off,
    portion_count_per_meal,
    promotional,
    shipping_price,
    status,
    tracking_link,
    product_contents,
  } = apiDelivery

  const recipeIds = contents.map(({ recipe_id: recipeId }) => recipeId)

  const recipes = contents.reduce<Delivery['recipes']>((acc, content) => {
    const { recipe_id: recipeId, price } = content

    const existing = acc.find((current) => current.id === recipeId)

    if (!existing) {
      acc.push({
        id: recipeId,
        portions: portion_count_per_meal,
        quantity: 1,
        pricePerPerson: parseFloat(price) / portion_count_per_meal,
        price: parseFloat(price),
      })
    }

    if (existing) existing.quantity += 1

    return acc
  }, [])

  const recipesQuantity = recipes.reduce((acc, r) => r.quantity + acc, 0)

  const today = dateFormats.iso(new Date())
  const daysUntilDelivery = differenceInCalendarDays(delivery_date, today)
  const productContents: ProductContent[] = (product_contents || []).map(
    ({ product_id, unit_price, quantity }): ProductContent => ({
      id: product_id,
      price: parseFloat(unit_price),
      quantity,
    })
  )

  const recipesAndProductContents = [...recipes, ...productContents]

  return {
    deliveryId: id,
    deliveryDate: delivery_date,
    deliveryOptionId: delivery_option_id,
    portionCount: portion_count_per_meal,
    trackingLink: tracking_link,
    status,
    recipeIds,
    recipes,
    recipesQuantity,
    productContents,
    totalPrice: getTotal(
      recipesAndProductContents.map(
        (item) => Number(item.price) * item.quantity
      )
    ),
    shippingPrice: parseFloat(shipping_price) || 0,

    isOneOff: one_off,
    isPromotional: promotional,
    isCustomerEdited: contents_assigner?.name === 'customer',
    isInFuture: isAfter(delivery_date, today),
    isInPast: isBefore(delivery_date, today),
    isToday: isEqual(delivery_date, today),
    isInBuildTime: daysUntilDelivery < DELIVERY_EDIT_GRACE_PERIOD,
  }
}
