import React, { ReactElement } from 'react'
import { MindfulChefLogo } from '@mindfulchefuk/design-system/Logos/MindfulChefLogo'
import { Box } from '@mindfulchefuk/design-system'
import {
  NavItem,
  NavLink,
  MobileNav,
  NavSignInCTA,
  NavChooseRecipesCTA,
} from '@mindfulchefuk/features/Navigation/components/Header/shared'
import { NavLinkData } from '@mindfulchefuk/features/Navigation/interfaces'
import { isBBCGoodFoodSession } from '@mindfulchefuk/features/Homepage/utils/isBBCGoodFoodSession'
import { trackAnalyticsEvent } from '@mindfulchefuk/utils/analytics/amplitude/amplitudeAdapter'

type TSignedOutNavMobile = {
  activeItem: string
  setActiveItem: (item: string) => void
  hamburgerOpen: boolean
  setHamburgerOpen: (open: boolean) => void
  links: NavLinkData[]
}

export const SignedOutNavMobile = ({
  activeItem,
  hamburgerOpen,
  setActiveItem,
  setHamburgerOpen,
  links,
}: TSignedOutNavMobile): ReactElement => {
  const handleCloseEverything = () => {
    setHamburgerOpen(false)
    setActiveItem(null)
  }
  const origin = isBBCGoodFoodSession() ? 'BBC homepage ' : ''

  return (
    <MobileNav isOpen={hamburgerOpen} onClose={handleCloseEverything}>
      <Box px={24} pb={12} pt={24} color="aubergine">
        <MindfulChefLogo color="aubergine" height={28} mb={12} />

        {links.map((link) => {
          if (link.subNav) {
            const isActive = activeItem === link.id
            return (
              <Box key={link.id}>
                <NavItem
                  key={link.id}
                  hasSubMenu
                  icon={link.icon}
                  id={link.id}
                  active={isActive}
                  onClick={() => setActiveItem(isActive ? null : link.id)}
                >
                  {link.label}
                </NavItem>
                <Box display={isActive ? 'block' : 'none'} listStyleType="none">
                  {link.subNav.map((sublink) => (
                    <Box key={sublink.id} as="li" pl="30px">
                      <NavLink
                        {...sublink}
                        onClick={handleCloseEverything}
                        external={sublink.route === 'external'}
                      />
                    </Box>
                  ))}
                </Box>
              </Box>
            )
          }

          return (
            <NavItem
              {...link}
              key={link.id}
              href={link.href}
              hasSubMenu={false}
              icon={link.icon}
              onClick={handleCloseEverything}
            >
              {link.label}
            </NavItem>
          )
        })}
      </Box>
      <Box
        px={24}
        py={12}
        borderTopWidth={1}
        color="aubergine"
        borderColor="pumpkin-200"
      >
        <NavItem
          id="help"
          hasSubMenu={false}
          href="https://help.mindfulchef.com/"
          onClick={handleCloseEverything}
        >
          Help
        </NavItem>

        <Box mt={16}>
          <NavChooseRecipesCTA
            fullWidth
            onClick={() => {
              handleCloseEverything()
              trackAnalyticsEvent({
                name: 'recipe CTA clicked',
                data: {
                  'CTA location': `${origin}header CTA`,
                },
              })
            }}
          />
        </Box>
        <Box mt={16}>
          <NavSignInCTA fullWidth onClick={handleCloseEverything} />
        </Box>
      </Box>
    </MobileNav>
  )
}
