import { Box } from '@mindfulchefuk/design-system/layout'
import React, { ReactElement } from 'react'
import NextLink from 'next/link'
import routesConfig from '@mindfulchefuk/config/routesConfig'
import { Roundel } from '@mindfulchefuk/design-system/Roundel/Roundel'
import { Icon } from '@mindfulchefuk/design-system/Icon'
import { theme } from '@mindfulchefuk/design-system/system/theme'
import { useNavClickHandler } from '@mindfulchefuk/features/Navigation/hooks'
import { NavCTA } from '@mindfulchefuk/features/Navigation/components/Header/shared/NavCTA'

const { broccoli } = theme.colors

export const NavMyAccountCTA = ({
  onClick = () => ({}),
}: {
  onClick?: () => void
}): ReactElement => {
  const testId = 'nav-cta-my-account'
  const handleRoundelClick = useNavClickHandler({
    action: 'nav-cta-clicked',
    label: testId,
    onClick,
  })

  return (
    <>
      <Box display={{ base: 'block', sm: 'none' }}>
        <NextLink href={{ pathname: routesConfig.upcoming.pathname }} passHref>
          <Box as="a" data-testid={testId} onClick={handleRoundelClick}>
            <Roundel filled size={48} color={broccoli}>
              <Icon type="person" color="white" />
            </Roundel>
          </Box>
        </NextLink>
      </Box>
      <Box display={{ base: 'none', sm: 'block' }}>
        <NavCTA
          variant="primary"
          testId={testId}
          onClick={onClick}
          href={routesConfig.upcoming.pathname}
        >
          My account
        </NavCTA>
      </Box>
    </>
  )
}
