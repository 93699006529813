import React, { ReactElement } from 'react'
import { BannerNotification } from '@mindfulchefuk/features/Notifications/components/BannerNotification'
import NextLink from 'next/link'
import { Link } from '@mindfulchefuk/design-system'

export const NotificationErrorWaitroseCustomersVitalitySignIn =
  (): ReactElement => (
    <BannerNotification
      variant="error"
      id="error-waitrose-customers-vitality-signin"
      title=""
      isDismissable
    >
      Customers that order recipe boxes through the Waitrose Mindful Chef
      partnership are not eligible to use the Vitality promotional discount.{' '}
      <br />
      Please click{' '}
      <NextLink href="/users/sign-in" passHref>
        <Link>here</Link>
      </NextLink>{' '}
      to be redirected to Mindful Chef & Waitrose sign in page
    </BannerNotification>
  )
