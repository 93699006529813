import { format } from 'date-fns'

// iso
// 2020-03-14

// summary
// e.g. January 1st

// short
// e.g. Sat 1st Jan

// long
// e.g. Saturday 1st January

// timeFormat
// 12:00am, 11:43am

//fullWeekdayAbbvMonthDay
// Saturday, Jan 1

export const dateFormats = {
  iso: (date: Date | string): string => format(date, 'YYYY-MM-DD'),
  summary: (date: Date | string): string => format(date, 'MMMM Do'),
  short: (date: Date | string): string => format(date, 'ddd Do MMM'),
  long: (date: Date | string): string => format(date, 'dddd Do MMMM'),
  timeFormat: (date: Date | string): string => format(date, 'h:mma'),
  fullWeekdayAbbvMonthDay: (date: Date | string): string =>
    format(date, 'dddd, MMM D'),
}
