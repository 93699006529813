import { subDays } from 'date-fns'
import { getImportantDates } from '@mindfulchefuk/features/Operations/utils/getImportantDates'
import { useGetDateDeliverabilities } from '@mindfulchefuk/features/Delivery/hooks/useGetDateDeliverabilities'
import { ORDER_RANGE } from '@mindfulchefuk/constants'
import { DateDeliverability } from '@mindfulchefuk/features/Delivery/interfaces'
import { dateFormats } from '@mindfulchefuk/lib/dateFormats'

export type TAvailableDates = {
  deliverabilities: DateDeliverability[]
  isLoading: boolean
  recipes: {
    availableDates: string[]
    firstAvailableDeliveryDate: string
    lastAvailableDeliveryDate: string
    unavailableDates: string[]
  }
}

export const useAvailableDates = (): TAvailableDates => {
  const { firstBuildDate, maxPastDate, tomorrow } = getImportantDates()

  const { data: dateDeliverabilities, isLoading } = useGetDateDeliverabilities(
    new Date(tomorrow),
    new Date(maxPastDate)
  )

  const [recipeAvailableDates, recipeUnavailableDates] =
    dateDeliverabilities.reduce(
      ([availableDates, unavailableDates], date) => {
        if (date.recipeDeliverable) {
          availableDates.push(date.deliveryDate)
        } else {
          unavailableDates.push(date.deliveryDate)
        }
        return [availableDates, unavailableDates]
      },
      [[], []]
    )

  return {
    deliverabilities: dateDeliverabilities,
    isLoading,
    recipes: {
      availableDates: recipeAvailableDates,
      firstAvailableDeliveryDate: recipeAvailableDates[0] || firstBuildDate,
      lastAvailableDeliveryDate:
        recipeAvailableDates[recipeAvailableDates.length - 1] ||
        dateFormats.iso(subDays(tomorrow, ORDER_RANGE)),
      unavailableDates: recipeUnavailableDates,
    },
  }
}
