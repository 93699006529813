import React, { useState, useMemo, useEffect, ReactElement } from 'react'
import { Box, Flex, Stack } from '@mindfulchefuk/design-system/layout'
import { Text } from '@mindfulchefuk/design-system/Text'
import { NavLinkData } from '@mindfulchefuk/features/Navigation/interfaces'
import { MegaNavImage } from './MegaNavImage'
import { NavLink } from './NavLink'

type TMegaNav = NavLinkData & {
  handleClose: () => void
}

export const MegaNav = ({
  label,
  subNav: links,
  handleClose,
  focusLinkId,
  staticLinkId,
  isDynamic,
}: TMegaNav): ReactElement => {
  const focusLink: NavLinkData = useMemo(
    () => links.find((l) => l.id === focusLinkId),
    [links, focusLinkId]
  )
  const staticLink: NavLinkData = links.find((l) => l.id === staticLinkId)

  const [activeLink, setActiveLink] = useState<NavLinkData>(focusLink)

  useEffect(() => setActiveLink(focusLink), [focusLink])

  const [focus, setFocus] = useState<boolean>(false)

  return (
    <Box
      width="928px"
      height="380"
      py={24}
      backgroundColor="white"
      borderWidth="0.5px"
      borderTopWidth={2}
      borderColor="aubergine"
      listStyleType="none"
      zIndex="nav"
      position="absolute"
      top="75px"
      left={{ base: '50%', lg: '100px' }}
      transform={{ base: 'translateX(-50%)', lg: 'translateX(0)' }}
      boxShadow="standard"
    >
      <Text variant="heading-sm" ml={32} mb={32} color="aubergine">
        {label}
      </Text>
      <Flex justify="space-between">
        <Box
          onMouseLeave={() => {
            setActiveLink(links[0])
            setFocus(false)
          }}
        >
          {links.map((link) => (
            <Box
              key={link.id}
              as="li"
              pl="30px"
              onMouseOver={() => {
                setActiveLink(link)
                setFocus(true)
              }}
              onClick={handleClose}
            >
              <NavLink {...link} external={link.route === 'external'} />
            </Box>
          ))}
        </Box>
        <Stack
          px={32}
          borderLeftWidth={1}
          borderColor="pepper-50"
          direction="row"
          spacing={20}
        >
          <MegaNavImage
            imageUrl={isDynamic ? activeLink.imageURL : focusLink.imageURL}
            href={isDynamic ? activeLink.href : focusLink.href}
            title={isDynamic ? activeLink.label : focusLink.label}
            description={activeLink.description}
            focus={focus}
            isDynamic={isDynamic}
            external={
              isDynamic
                ? activeLink.route === 'external'
                : focusLink.route === 'external'
            }
          />
          <MegaNavImage
            imageUrl={staticLink.imageURL}
            title={staticLink.label}
            href={staticLink.href}
            external={staticLink.route === 'external'}
          />
        </Stack>
      </Flex>
    </Box>
  )
}
