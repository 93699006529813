import React, { useEffect } from 'react'
import { RootState } from '@mindfulchefuk/types/store'
import { useSelector } from 'react-redux'
import { dateFormats } from '@mindfulchefuk/lib/dateFormats'
import { BannerNotification } from '@mindfulchefuk/features/Notifications/components/BannerNotification'
import { triggerHotJarEvent } from '@mindfulchefuk/utils/analytics/hotJar/triggerHotJarEvent'

const id = 'success-delivery-cancelled'

export const NotificationSuccessDeliveryCancelled = () => {
  const date = useSelector(
    (store: RootState) => store.notifications.meta[id].date
  )

  useEffect(() => {
    setTimeout(() => {
      triggerHotJarEvent('recipe delivery skipped')
    }, 1000)
  }, [])

  return (
    <BannerNotification
      variant="success"
      title="Order cancelled"
      id={id}
      isDismissable
      shouldAutoDismiss
    >
      Your order for {dateFormats.long(date)} has been cancelled.
    </BannerNotification>
  )
}
