import React, { ReactElement } from 'react'
import { Dropdown, IDropdown } from '@mindfulchefuk/design-system/Dropdown'
import Illustration from '@mindfulchefuk/design-system/Illustrations/Illustrations'
import { VisuallyHidden } from '@mindfulchefuk/design-system/VisuallyHidden'

type IllustratedDropdownVariant = 'cucumber' | 'almonds' | 'aubergine'

export interface IIllustratedDropdown extends IDropdown {
  variant?: IllustratedDropdownVariant
}

export const IllustratedDropdown = ({
  children,
  variant,
  ...props
}: IIllustratedDropdown): ReactElement => (
  <>
    {/* Preload the images so they don't have to load when hovered */}
    <VisuallyHidden>
      {variant === 'almonds' && (
        <Illustration position="static" type="cross-section-almond-two" />
      )}
      {variant === 'cucumber' && (
        <Illustration position="static" type="cross-section-cucumber" />
      )}
      {variant === 'aubergine' && (
        <Illustration position="static" type="chopped-aubergine-three" />
      )}
    </VisuallyHidden>
    <Dropdown {...props} overflow="hidden">
      {variant === 'almonds' && (
        <>
          <Illustration
            type="cross-section-almond-two"
            transform="rotate(180deg)"
            right={12}
            bottom={-16}
          />
          <Illustration
            top={56}
            right={-12}
            type="cross-section-almond-two"
            transform="rotate(270deg)"
          />
          <Illustration
            top={-36}
            right={-24}
            type="cross-section-almond-two"
            transform="rotate(90deg)"
          />
        </>
      )}
      {variant === 'cucumber' && (
        <>
          <Illustration
            type="cross-section-cucumber"
            right={-80}
            top={-72}
            transform="scale(0.5)"
          />
          <Illustration
            right={-96}
            bottom={-48}
            type="cross-section-cucumber"
            transform="scale(0.8)"
          />
        </>
      )}
      {variant === 'aubergine' && (
        <>
          <Illustration
            type="chopped-aubergine-three"
            right={-56}
            top={-48}
            transform="scale(0.5) rotate(240deg)"
          />
          <Illustration
            type="chopped-aubergine-three"
            top={96}
            right={-48}
            transform="scale(0.8)"
          />
        </>
      )}
      {children}
    </Dropdown>
  </>
)
