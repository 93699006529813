import React, { ReactElement } from 'react'

import {
  Button,
  Text,
  Flex,
  Stack,
  Modal,
  ModalHeader,
  ModalBody,
} from '@mindfulchefuk/design-system'
import { Subscription } from '@mindfulchefuk/features/Subscription/interfaces'
import { prettifyWeekdays } from '@mindfulchefuk/features/Subscription/utils/prettifyWeekdays'
import { displayDixaMessenger } from '@mindfulchefuk/features/Dixa/displayDixaMessenger'
import { trackAnalyticsEvent } from '@mindfulchefuk/utils/analytics/amplitude/amplitudeAdapter'

export const ResumeSubscriptionModal = ({
  onResumeClick,
  onClose,
  subscription,
}: {
  subscription: Subscription
  onClose: () => void
  onResumeClick: () => void
}): ReactElement => {
  const { deliveryWeekdays, deliveryFrequencyWeeks } = subscription

  const prettyDays = prettifyWeekdays(deliveryWeekdays)

  const prettyFrequency =
    deliveryFrequencyWeeks === 1 ? 'week' : `${deliveryFrequencyWeeks} weeks`

  const onCloseWrapper = () => {
    displayDixaMessenger(true)
    onClose()
  }
  const handleAbort = () => {
    trackAnalyticsEvent({
      name: 'resume subscription cancelled',
    })
    onCloseWrapper()
  }

  return (
    <Modal
      data-testid="resume-subscription-modal"
      size="medium"
      closeModal={handleAbort}
    >
      <ModalHeader closeModal={handleAbort}>
        <Text variant="heading-sm" as="h3" color="aubergine">
          Resume subscription?
        </Text>
      </ModalHeader>
      <ModalBody>
        <Flex align="center" flexDirection="column">
          <Text variant="body-lg" mb={24}>
            You’ll receive your subscription boxes every {prettyFrequency} on{' '}
            {prettyDays}.
          </Text>

          <Stack spacing={16}>
            <Button
              fullWidth
              variant="primary"
              size="medium"
              onClick={onResumeClick}
            >
              Yes, resume subscription
            </Button>
            <Button
              fullWidth
              variant="secondary"
              size="medium"
              onClick={() => {
                trackAnalyticsEvent({
                  name: 'resume subscription cancelled',
                })
                onCloseWrapper()
              }}
            >
              Cancel
            </Button>
          </Stack>
        </Flex>
      </ModalBody>
    </Modal>
  )
}
