import React, { ReactElement, useState } from 'react'
import { format } from 'date-fns'

import {
  Button,
  Text,
  Modal,
  ModalHeader,
  ModalBody,
  ModalTitle,
  ModalMobileCTA,
  Box,
  Stack,
  RadioBox,
  ButtonLink,
  space,
  colors,
} from '@mindfulchefuk/design-system'
import { PauseDelivery } from '@mindfulchefuk/features/Subscription/interfaces'
import { trackAnalyticsEvent } from '@mindfulchefuk/utils/analytics/amplitude/amplitudeAdapter'

export const PauseSubscriptionModal = ({
  dates,
  onSubmit,
  onClose,
}: {
  dates: PauseDelivery[]
  onClose: () => void
  onSubmit: (pausedUntilDate: PauseDelivery) => void
}): ReactElement => {
  const [dateSelected, setDateSelected] = useState<PauseDelivery>({
    deliveryDate: '',
    deliveryId: '',
    isFirstDeliveryOfTheYear: false,
  })
  const [showInfoMessage, setShowInfoMessage] = useState(false)

  const firstDate = dates[0]
  const selectableDates = dates.slice(1)

  return (
    <Modal size="medium" data-testid="pause-subscription-modal">
      <ModalHeader closeModal={onClose}>
        <ModalTitle>Pause subscription</ModalTitle>
      </ModalHeader>
      <ModalBody mb={[64, 0]}>
        <Text variant="body-md" mb={16}>
          Choose{' '}
          <Text display="inline" variant="body-md-500">
            the date you would like to resume
          </Text>{' '}
          your recipe deliveries from your upcoming delivery dates below.
        </Text>
        <Text variant="body-sm" mb={36}>
          Note that Ready to Go deliveries will not be affected.
        </Text>
        <Stack spacing={10} mb={24} role="group">
          <Box
            padding={space[16]}
            borderRadius={space[10]}
            data-testid="pause-subscription-first-date"
            backgroundColor="pepper-50"
            border={`1px solid ${colors['pepper-50']}`}
            color="pepper-500"
          >
            <Text variant="body-lg-500" mb={6}>
              {format(firstDate.deliveryDate, 'ddd D MMMM')}
            </Text>
            <Text variant="body-sm">This is already your next delivery</Text>
          </Box>
          {selectableDates.map(
            ({ deliveryDate, deliveryId, isFirstDeliveryOfTheYear }) => (
              <RadioBox
                key={deliveryId}
                value={deliveryDate}
                checked={dateSelected.deliveryDate === deliveryDate}
                data-testid="pause-subscription-date"
                onChange={() => {
                  trackAnalyticsEvent({
                    name: 'pause until delivery chosen',
                    data: {
                      'delivery id': deliveryId,
                      'delivery date': deliveryDate,
                    },
                  })
                  setDateSelected({
                    deliveryDate,
                    deliveryId,
                    isFirstDeliveryOfTheYear,
                  })
                }}
                disabled={!deliveryDate}
              >
                <>
                  {format(deliveryDate, 'ddd D MMMM')}
                  {isFirstDeliveryOfTheYear && (
                    <Text mt={4} variant="body-sm" color="broccoli">
                      First healthy box of the year!
                    </Text>
                  )}
                </>
              </RadioBox>
            )
          )}
        </Stack>
        <Box mb={12}>
          <ButtonLink
            textDecoration="underline"
            as="span"
            color="broccoli"
            onClick={() => {
              trackAnalyticsEvent({
                name: 'pause for longer expanded',
              })
              setShowInfoMessage(!showInfoMessage)
            }}
          >
            Need to pause for longer?
          </ButtonLink>
        </Box>
        {showInfoMessage && (
          <Text variant="body-md" mb={24}>
            Unfortunately we can only pause deliveries up to 8 weeks ahead for
            now, but we’re hoping to introduce the ability to pause for longer
            in the future!
          </Text>
        )}
      </ModalBody>
      <ModalMobileCTA borderTopWidth={1}>
        <Box p={{ base: 0, sm: 12 }}>
          <Button
            fullWidth
            variant="primary"
            size="medium"
            onClick={() => onSubmit(dateSelected)}
            disabled={!dateSelected.deliveryDate}
          >
            Pause subscription
          </Button>
        </Box>
      </ModalMobileCTA>
    </Modal>
  )
}
