import React, { ReactElement } from 'react'
import { MindfulChefLogo } from '@mindfulchefuk/design-system/Logos/MindfulChefLogo'
import {
  Box,
  BoxProps,
  Container,
  Flex,
  HEADER_MAX_WIDTH,
  HEADER_PADDING,
} from '@mindfulchefuk/design-system'
import { SignedInNav } from '@mindfulchefuk/features/Navigation/components/Header/SignedInNav'
import { SignedOutNav } from '@mindfulchefuk/features/Navigation/components/Header/SignedOutNav'
import { useIsNewCustomer } from '@mindfulchefuk/features/Onboarding/hooks/useIsNewCustomer'
import { LogoLinkWrapper } from './LogoLinkWrapper'

export type THeader = {
  customNav?: ReactElement
  variant?: 'light' | 'dark'
  logo?: ReactElement
  logoDisplay?: BoxProps['display']
  hideNav?: boolean
  isSticky?: boolean
  isOnboarding?: boolean
  disableLogoLink?: boolean
}

export const Header = ({
  variant = 'light',
  logo = (
    <MindfulChefLogo
      color={variant === 'light' ? 'aubergine' : 'white'}
      height={{ base: 24, md: 28 }}
    />
  ),
  hideNav,
  customNav,
  logoDisplay,
  isSticky,
  isOnboarding,
  disableLogoLink = false,
}: THeader): ReactElement => {
  const bg = variant === 'dark' ? 'aubergine' : 'salt'
  const color = variant === 'dark' ? 'white' : 'aubergine'

  const { isCustomer } = useIsNewCustomer()

  return (
    <Box
      backgroundColor={bg}
      position={isSticky ? 'sticky' : 'relative'}
      top={isSticky ? 0 : undefined}
      z={isSticky ? 'header' : undefined}
      as="header"
    >
      <Container
        maxWidth={HEADER_MAX_WIDTH}
        px={HEADER_PADDING}
        position="relative"
        color={color}
      >
        <Flex minHeight={{ base: 80, sm: 96 }} align="center">
          <LogoLinkWrapper
            isOnboarding={isOnboarding}
            disableLink={disableLogoLink}
            logo={logo}
            display={logoDisplay}
          />
          {!hideNav && isCustomer && <SignedInNav variant={variant} />}
          {!hideNav && !isCustomer && (
            <SignedOutNav isOnboarding={isOnboarding} variant={variant} />
          )}
          {customNav}
        </Flex>
      </Container>
    </Box>
  )
}
