import { isToday, isAfter, isBefore } from 'date-fns'
import { UseQueryOptions, useQuery } from '@tanstack/react-query'
import {
  getCMSPage,
  CMSPageApiPath,
} from '@mindfulchefuk/features/CMS/services/getCMSPage'
import {
  CMSPageHomepage,
  CMSPage,
  CMSPageReferral,
  CMSPageAccountPreference,
} from '@mindfulchefuk/features/CMS/interfaces'
import { STALE_10_MINS } from '@mindfulchefuk/constants'

type CMSPageReturnType<T extends CMSPageApiPath> = T extends 'page-homepage'
  ? CMSPageHomepage
  : T extends 'page-referral'
  ? CMSPageReferral
  : T extends 'page-account-preference'
  ? CMSPageAccountPreference
  : CMSPage

export const useCMSPage = <
  T extends CMSPageApiPath,
  TData = CMSPageReturnType<T>
>(
  page: T,
  options: UseQueryOptions<CMSPageReturnType<T>, unknown, TData> = {}
) => {
  return useQuery({
    queryKey: [`cms-${page}`],
    queryFn: () => getCMSPage(page) as Promise<CMSPageReturnType<T>>,
    staleTime: STALE_10_MINS,
    ...options,
  })
}

export const selectProductAnnouncement = (
  pageData: CMSPage | CMSPageHomepage
) => {
  const { product_announcement: productAnnouncement } = pageData

  if (!productAnnouncement?.data) return null
  const { attributes } = productAnnouncement.data

  const shouldAnnouncementBeLive =
    (isToday(new Date(attributes.startDate)) ||
      isAfter(new Date(), new Date(attributes.startDate))) &&
    isBefore(new Date(), new Date(attributes.endDate))

  if (!shouldAnnouncementBeLive) {
    return null
  }

  return attributes
}
