import React from 'react'
import { useRouter } from 'next/router'
import {
  Box,
  Flex,
  PandaButton,
  Text,
  Notification,
} from '@mindfulchefuk/design-system'
import { useUpdateSubscription } from '@mindfulchefuk/features/Subscription/hooks/useUpdateSubscription'
import { ANALYTICS_CATEGORIES } from '@mindfulchefuk/constants'
import { logEvent } from '@mindfulchefuk/utils/analytics'
import routesConfig from '@mindfulchefuk/config/routesConfig'
import { triggerHotJarEvent } from '@mindfulchefuk/utils/analytics/hotJar/triggerHotJarEvent'
import { usePageSource } from '@mindfulchefuk/utils/analytics/amplitude/hooks/usePageSource'
import { trackAnalyticsEvent } from '@mindfulchefuk/utils/analytics/amplitude/amplitudeAdapter'

export const NotificationWarningAccountIsDeactivated = () => {
  const router = useRouter()
  const pageSource = usePageSource()

  const { mutate: updateSubscription } = useUpdateSubscription({
    onSuccess: async () => {
      triggerHotJarEvent('restart subscription confirmed')
      trackAnalyticsEvent({
        name: 'subscription reactivate confirmed',
        data: {
          'page source': pageSource,
        },
      })
      router.push(
        {
          pathname: routesConfig.upcoming.pathname,
          query: { subscriptionRestarted: true },
        },
        routesConfig.upcoming.as
      )
    },
  })

  const handleRestartSubscription = () => {
    logEvent({
      category: ANALYTICS_CATEGORIES.myAccount,
      action: 'Restart Subscription Button',
      label: 'Click',
    })

    updateSubscription({ cancelledAt: null })
  }

  const handleOrderOneOffBoxClicked = () => {
    trackAnalyticsEvent({
      name: 'order one off box clicked',
      data: {
        'page source': pageSource,
      },
    })
    window.localStorage.setItem('clickedOneOffBoxButton', 'true')
    router.push('/my-account/orders/calendar')
  }

  return (
    <Notification
      type="banner"
      variant="warning"
      title="Subscription deactivated"
      id="warning-account-is-deactivated"
    >
      <Text width="fit-content">
        You do not have an active subscription, but you can buy Ready to Go or
        get a one-off box anytime!
      </Text>
      <Flex width="fit-content" mt={12}>
        <PandaButton
          variant="primary"
          size="small"
          onClick={handleRestartSubscription}
        >
          Restart subscription
        </PandaButton>
        <Box ml={12}>
          <PandaButton
            variant="primary-white"
            size="small"
            onClick={handleOrderOneOffBoxClicked}
          >
            Order a one-off box
          </PandaButton>
        </Box>
      </Flex>
    </Notification>
  )
}
