import React, { ReactElement } from 'react'

import { Icon, IconType, Stack } from '@mindfulchefuk/design-system'
import routesConfig from '@mindfulchefuk/config/routesConfig'
import { MindfulChefLogo } from '@mindfulchefuk/design-system/Logos/MindfulChefLogo'
import { isAuthenticated } from '@mindfulchefuk/helpers/js/authentication'

import { FooterLink } from '@mindfulchefuk/features/Navigation/components/Footer/FooterLink'
import { Copyright } from '@mindfulchefuk/features/Navigation/components/Footer/Copyright'
import { BCorpLogo } from '@mindfulchefuk/features/Navigation/components/Footer/BCorpLogo'
import { ListItem } from '@mindfulchefuk/features/Navigation/components/Footer/ListItem'
import { BBCGoodFoodLogo } from '@mindfulchefuk/features/Homepage/components/BBCGoodFood/BBCGoodFoodLogo'

type TSocialLinkData = {
  path: string
  reference: IconType
}

type TMCDetails = {
  socialLinks: TSocialLinkData[]
  onSocialClick: (linkId: string) => void
  isBBCGoodFood: boolean
}

export const MCDetails = ({
  socialLinks,
  onSocialClick,
  isBBCGoodFood,
}: TMCDetails): ReactElement => {
  const isLoggedIn = isAuthenticated()

  return (
    <Stack as="ul" p={0} spacing={{ base: 16, md: 24 }}>
      <ListItem>
        <FooterLink
          href={
            isLoggedIn
              ? routesConfig.upcoming.pathname
              : routesConfig.home.pathname
          }
          reference="mc-logo"
          openInNewWindow={false}
        >
          {isBBCGoodFood ? (
            <BBCGoodFoodLogo
              onClick={() => onSocialClick('bbc-good-food-logo')}
              color="inherit"
              width="100%"
              maxWidth={144}
            />
          ) : (
            <MindfulChefLogo
              onClick={() => onSocialClick('mc-logo')}
              color="inherit"
              width="100%"
              maxWidth={144}
            />
          )}
        </FooterLink>
      </ListItem>

      <ListItem>
        <Stack direction="row" spacing={[16, 12, 16]} align="center" as="span">
          {socialLinks.map((channel) => (
            <FooterLink
              data-testid={`footer-link-${channel.reference}`}
              href={channel.path}
              key={channel.reference}
              reference={channel.reference}
              openInNewWindow
            >
              <Icon
                onClick={() => onSocialClick(channel.reference)}
                color="inherit"
                type={channel.reference}
                size={{ base: 28, md: 32 }}
                flexShrink={0}
              />
            </FooterLink>
          ))}
        </Stack>
      </ListItem>

      <ListItem display={{ base: 'none', md: 'block' }}>
        <BCorpLogo />
      </ListItem>

      <ListItem display={{ base: 'none', md: 'block' }}>
        <Copyright />
      </ListItem>
    </Stack>
  )
}
