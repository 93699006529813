import React, { ReactElement, ReactNode, useEffect } from 'react'

import Head from 'next/head'
import { useRouter } from 'next/router'

import { BoxProps } from '@mindfulchefuk/design-system/layout/Box'
import {
  Footer,
  TFooter,
  Header,
  THeader,
} from '@mindfulchefuk/features/Navigation'
import { BannerNotifications } from '@mindfulchefuk/features/Notifications/components/BannerNotifications'
import { useDatadogRumUser } from '@mindfulchefuk/hooks/useDatadogRumUser'
import { useNotifyIfOffline } from '@mindfulchefuk/hooks/useNotifyIfOffline'
import useVersionCheck from '@mindfulchefuk/hooks/useVersionCheck'
import { logPageView } from '@mindfulchefuk/utils/analytics'

export type TMainLayout = {
  title?: string
  description?: string
  children?: ReactNode
  canonical?: string
  customLogo?: ReactElement
  customNav?: ReactElement
  logoDisplay?: BoxProps['display']
  hasStickyHeader?: boolean
  hideFooter?: boolean
  hideNav?: boolean
  isOnboarding?: boolean
  headerProps?: THeader
  footerProps?: TFooter
}

const MainLayout = ({
  hideFooter = false,
  hideNav = false,
  customNav,
  logoDisplay,
  title,
  customLogo,
  canonical,
  description,
  children,
  hasStickyHeader,
  isOnboarding = false,
  headerProps = { variant: 'light' },
  footerProps = { variant: 'dark' },
}: TMainLayout): ReactElement => {
  const router = useRouter()

  useDatadogRumUser()
  useVersionCheck()
  useNotifyIfOffline()

  useEffect(() => {
    logPageView()
  }, [router.asPath])

  const pageTitle = `${title} | Mindful Chef`

  return (
    <>
      <Head>
        <link
          rel="canonical"
          href={`${process.env.NEXT_PUBLIC_CANONICAL}${
            canonical ? '/' : ''
          }${canonical}`}
        />
        <title>{pageTitle}</title>
        <meta
          property="og:title"
          content="Recipe Boxes – Fresh UK Healthy Food Box Delivery | Mindful Chef"
        />
        <meta
          property="og:image"
          content="https://mindfulchef-images-production.s3.eu-west-2.amazonaws.com/MC+sharing+preview+image.png"
        />
        {description && <meta name="description" content={description} />}
      </Head>
      <Header
        logo={customLogo}
        hideNav={hideNav}
        customNav={customNav}
        logoDisplay={logoDisplay}
        isSticky={hasStickyHeader}
        isOnboarding={isOnboarding}
        {...headerProps}
      />
      <BannerNotifications />
      <main>{children}</main>
      {!hideFooter && <Footer {...footerProps} />}
    </>
  )
}

export default MainLayout
