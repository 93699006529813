import { OperationsDateDeliverability } from '@mindfulchefuk/api-client'
import {
  APIDateDeliverability,
  DateDeliverability,
} from '@mindfulchefuk/features/Delivery/interfaces'
import errorHandler from '@mindfulchefuk/utils/errorHandler'

export interface GetDateDeliverabilitiesParams {
  minDate: string
  maxDate: string
  postcode: string
}

export const getDateDeliverabilities = async ({
  minDate,
  maxDate,
  postcode,
}: GetDateDeliverabilitiesParams): Promise<DateDeliverability[]> => {
  try {
    const { data: dateDeliverabilitiesData } =
      await OperationsDateDeliverability.where({
        postcode: { eq: postcode },
        delivery_date: {
          gte: minDate,
          lte: maxDate,
        },
      })
        .extraParams({ pagination_links: false })
        .all()

    return dateDeliverabilitiesData.map(
      (dateDeliverability: APIDateDeliverability) => {
        const {
          delivery_date: deliveryDate,
          recipe_deliverable: recipeDeliverable,
        } = dateDeliverability

        return {
          deliveryDate,
          postcode,
          recipeDeliverable,
        }
      }
    )
  } catch (err) {
    errorHandler(err)
  }
}
