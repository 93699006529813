import { HomepageVariantPath } from '@mindfulchefuk/features/Homepage/interfaces'
import { isObjTrue } from '@mindfulchefuk/utils/isObjTrue'
import { useRouter } from 'next/router'

const homepageVariants: HomepageVariantPath[] = [
  'index',
  'good-food',
  'family-recipe-boxes',
  'food-boxes',
  'healthy-meals',
  'healthy-recipe-boxes',
  'vegan-recipe-boxes',
]

export const isHomepageVariant = (pathname: HomepageVariantPath) =>
  homepageVariants.includes(pathname)

export const useIsHomepageVariant = (target: HomepageVariantPath) => {
  const { query } = useRouter()
  const currentHomepageVariant = query?.basePage

  const currentHomepage = homepageVariants.find(
    (variant) => variant === currentHomepageVariant
  )

  return currentHomepage === target
}

export const useIsBBCGoodFood = () => {
  const isBBCGoodFood = useIsHomepageVariant('good-food')
  return (
    isBBCGoodFood && isObjTrue(process.env.NEXT_PUBLIC_FEATURE_BBC_GOOD_FOOD)
  )
}
