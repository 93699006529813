import voucherConfig, {
  TVoucherConfig,
  GENERIC_VOUCHER_CONFIG,
} from '@mindfulchefuk/config/voucherConfig'

export const getDiscountConfig = (voucherCode: string): TVoucherConfig => {
  if (!voucherCode) return undefined

  const result: TVoucherConfig = voucherConfig.find((configItem) => {
    const code: string[] = Array.isArray(configItem.code)
      ? configItem.code
      : [configItem.code]

    // Dynamically create regexp to match voucherCode - e.g. /^(one|two|three|four).*$/
    const tester = new RegExp(`^.*(${code.join('|')}).*$`, 'i')

    return tester.test(voucherCode)
  })

  return result ?? GENERIC_VOUCHER_CONFIG
}
