import React, { ReactElement } from 'react'
import { WarningModal } from '@mindfulchefuk/components/MyAccount/WarningModal'
import { Button, Text } from '@mindfulchefuk/design-system'

export const UpdateUnsubscribedPreferencesModal = ({
  savePreferences,
  saveAndResume,
}: {
  savePreferences: (hasActiveSubscription: boolean) => void
  saveAndResume: () => void
}): ReactElement => (
  <WarningModal
    title="Would you like to restart your subscription?"
    data-testid="unsubscribedSavePreferencesModal"
    ctas={
      <>
        <Button
          variant="secondary"
          size="medium"
          onClick={saveAndResume}
          textWrap
        >
          Yes, restart subscription
        </Button>
        <Button
          variant="secondary"
          size="medium"
          onClick={() => savePreferences(false)}
          textWrap
        >
          No, just update my preferences
        </Button>
      </>
    }
  >
    <Text as="span" variant="body-md">
      You&apos;re about to update your preferences. Would you also like to
      restart your subscription?
    </Text>
    <Text as="span" variant="body-md-500">
      {' '}
      This will automatically schedule deliveries based on the settings you have
      selected so please check your calendar to ensure you are happy with the
      changes made.
    </Text>
  </WarningModal>
)
