import styled from '@emotion/styled'
import {
  IRoundel,
  RoundelContainerProps,
} from '@mindfulchefuk/design-system/Roundel/Roundel.types'
import { colors } from '@mindfulchefuk/design-system/system/theme/colors'
import { headingDefaults } from '../system'

export const RoundelContainer = styled.div<RoundelContainerProps>`
  margin: ${({ margin }) => margin};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
`

export const ContentWrapper = styled.div<IRoundel>`
  display: flex;
  position: absolute;
  margin: 0;
  padding: 0;
  font-size: ${({ fontSize }) => fontSize}px;
  font-family: ${headingDefaults.fontFamily};
  text-align: center;
  color: ${({ filled, color }) => (filled ? `${colors.white}` : `${color}`)};
  z-index: 5;
  line-height: 100%;
  opacity: ${({ disabled, disableOpacity }) =>
    disableOpacity || !disabled ? 1 : 0.5};
`
