import {
  DELIVERY_EDIT_GRACE_PERIOD,
  ORDER_RANGE,
} from '@mindfulchefuk/constants'
import { dateFormats } from '@mindfulchefuk/lib/dateFormats'
import { addDays } from 'date-fns'

export function getImportantDates() {
  const recipeBoxBuildTime = DELIVERY_EDIT_GRACE_PERIOD
  const orderLimit = ORDER_RANGE
  const now = new Date()
  const tomorrow = addDays(now, 1)

  const firstBuildDateFromToday = addDays(now, recipeBoxBuildTime)
  const maxPastDate = addDays(now, orderLimit)

  return {
    firstBuildDate: dateFormats.iso(firstBuildDateFromToday),
    tomorrow: dateFormats.iso(tomorrow),
    maxPastDate: dateFormats.iso(maxPastDate),
  }
}
