import React, { ReactElement } from 'react'

import {
  Button,
  Text,
  Stack,
  Modal,
  ModalHeader,
  ModalBody,
  Grid,
} from '@mindfulchefuk/design-system'
import { Subscription } from '@mindfulchefuk/features/Subscription/interfaces'
import { SubscriptionIcons } from './SubscriptionIcons'

type TRestartSubscriptionSuccessModal = {
  // Used to determine what copy to show on the buttons (where "Back to" is)
  source: 'orders' | 'preferences'
  // This handles both "reactivate" and "resume" use cases
  type: 'activated' | 'resumed'
  onClose: () => void
  onPreferencesClick: () => void
  onOrdersClick: () => void
  subscription: Subscription
}

export const RestartSubscriptionSuccessModal = ({
  onOrdersClick,
  onClose,
  onPreferencesClick,
  source,
  subscription,
  type,
}: TRestartSubscriptionSuccessModal): ReactElement => (
  <Modal
    data-testid="resume-subscription-success-modal"
    size="medium"
    closeModal={onClose}
  >
    <ModalHeader closeModal={onClose}>
      <Text variant="heading-sm" as="h3" color="aubergine">
        Subscription {type} successfully
      </Text>
    </ModalHeader>
    <ModalBody>
      <Text variant="body-lg" mb={24}>
        The recipes for all your upcoming deliveries have been reset according
        to your subscription preferences. Any one-off deliveries you have placed
        will not be affected.
      </Text>
      <Grid width="100%" mb={16} gap={6}>
        <SubscriptionIcons subscription={subscription} includeDayOfTheWeek />
      </Grid>

      <Text
        variant="body-lg-500"
        mb={24}
        textAlign="left"
        alignSelf="flex-start"
      >
        Please double-check your orders to make sure your deliveries are as
        expected
        {type === 'activated'
          ? ' and your delivery address is up to date.'
          : '.'}
      </Text>

      <Stack spacing={16}>
        <Button
          fullWidth
          variant="primary"
          size="medium"
          onClick={onOrdersClick}
        >
          {source === 'orders' ? 'Back' : 'Go'} to your orders
        </Button>
        <Button
          fullWidth
          variant="secondary"
          size="medium"
          onClick={onPreferencesClick}
        >
          {source === 'preferences' ? 'Back to' : 'Edit'} preferences
        </Button>
      </Stack>
    </ModalBody>
  </Modal>
)
